import './coin-listing.style.css'
import {useEffect, useState} from "react";
import {CoinService} from "../../services/services/coin/coin.service";
import {CoinListModel} from "../../services/models/coin-list.model";
import {roundNumberWithPriceUnits, roundPrice} from "../../services/utils/utils";
import ReactGA from "react-ga";
import largeLogo from "../../assets/images/large-logo.svg";
import smallLogo from "../../assets/images/small-logo.svg";
import {useNavigate} from "react-router-dom";

export const CoinListingComponent = () => {

    const navigate = useNavigate();

    const [width, setWindowWidth] = useState(0);
    const [isPhoneFormat, setIsPhoneFormat] = useState(true);
    const [numberOfPages, setNumberOfPages] = useState(-1);
    const [currentPageNumber, setCurrentPageNumber] = useState(CoinService.currentUserPage$.getValue());
    const [coinsOnPages, setCoinsOnPages] = useState<CoinListModel[]>([]);


    const percentageOfCirculatingSupply = (coinOnPage: CoinListModel): string => {
        if (coinOnPage.max_supply) {
            return `${Math.round(100 * coinOnPage.circulating_supply / coinOnPage.max_supply)} %`
        } else {
            return `∞ supply`
        }
    }

    const isInfitiniteSupply = (coinOnPage: CoinListModel): boolean => {
        return !!coinOnPage.max_supply;

    }

    useEffect(() => {
        CoinService.updateCoinsOnPage(CoinService.currentUserPage$.getValue()).then(cop => setCoinsOnPages(cop)).catch(e => console.error(e));
        CoinService.updateNumberOfPages().then(nop => setNumberOfPages(nop)).catch(e => console.error(e));

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () =>
            window.removeEventListener("resize",updateDimensions);
    }, [setNumberOfPages, setCoinsOnPages]);

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
        if (width > 1023) {
            setIsPhoneFormat(false)
        } else {
            setIsPhoneFormat(true)
        }
    }

    const nextPage = () => {
        if (currentPageNumber < numberOfPages) {
            CoinService.updateCoinsOnPage(currentPageNumber + 1).then(cop => setCoinsOnPages(cop)).catch(e => console.error(e));
            CoinService.currentUserPage$.next(CoinService.currentUserPage$.getValue() + 1)
            setCurrentPageNumber(CoinService.currentUserPage$.getValue())

            ReactGA.event({
                category: 'HOME_PAGE_NAVIGATION',
                action: 'NEXT_PAGE',
                label: 'NAVIGATION',
            })
        }
    }

    const lastPage = () => {
        if (currentPageNumber > 1) {
            CoinService.updateCoinsOnPage(currentPageNumber - 1).then(cop => setCoinsOnPages(cop)).catch(e => console.error(e));
            CoinService.currentUserPage$.next(CoinService.currentUserPage$.getValue() - 1)
            setCurrentPageNumber(CoinService.currentUserPage$.getValue())
            ReactGA.event({
                category: 'HOME_PAGE_NAVIGATION',
                action: 'LAST_PAGE',
                label: 'NAVIGATION',
            })
        }

    }

    const goToCoinInfosPage = (coinId: string) => {
        navigate(`/coin/${coinId}`);
    }



    return (
        <>
            <section className="MainBodySection" >

                <table className="CoinListingTable">
                    <thead className={`CoinListingTableHead ${(isPhoneFormat) ? 'PhoneScreenLineHeight' : 'ComputerScreenLineHeight'}`}>
                        {(isPhoneFormat)
                            ?
                                <tr>
                                    <th className="AlignLeft">Name & Price</th>
                                    <th className="AlignRight">Supply already mined</th>
                                </tr>
                            :
                                <tr>
                                    <th className="AlignLeft">Name</th>
                                    <th className="AlignRight">Price</th>
                                    <th className="AlignRight">Market Cap</th>
                                    <th className="AlignRight">Circulating Supply</th>
                                    <th className="AlignRight">Max Supply</th>
                                    <th className="AlignRight">Supply already mined</th>
                                </tr>
                        }

                    </thead>
                    <tbody className={`${(isPhoneFormat) ? 'PhoneScreenLineHeight' : 'ComputerScreenLineHeight'}`}>
                        {
                            coinsOnPages.map(coinOnPage => {
                                return <tr key={`${coinOnPage.id}-phone`} onClick={() => goToCoinInfosPage(`${coinOnPage.id}`)}>
                                    {(isPhoneFormat)
                                        ?
                                            <>
                                                <td className="AlignLeft TrWithImage">
                                                    <img src={coinOnPage.image} alt={`${coinOnPage.name} logo`} className="LogoForCoins"/>
                                                    <div className="CoinListingPhoneNameAndPrice">

                                                        <div className="CoinNameAndSymbol">
                                                            <a  className="BlackColor NonUnderlinedHref" style={{fontSize: '14px'}}>{coinOnPage.name}</a>
                                                            <a href={`/coin/${coinOnPage.id}`}  className="SymbolStyle NonUnderlinedHref" style={{fontSize: '8px'}}>{coinOnPage.symbol.toUpperCase()}</a>
                                                        </div>
                                                        <div style={{fontSize: '12px'}}>${roundPrice(coinOnPage.current_price)}</div>
                                                    </div>

                                                </td>
                                                <td className={`AlignRight ${isInfitiniteSupply(coinOnPage) ? "GreenColor" : "RedColor"}`}>
                                                    <div className="CoinListingPhoneInflation">
                                                        <div style={{fontSize: '13px'}}>{percentageOfCirculatingSupply(coinOnPage)}</div>
                                                    </div>

                                                </td>
                                            </>
                                        :
                                             <>
                                                <td className="AlignLeft TrWithImage">
                                                    <img src={coinOnPage.image} alt={`${coinOnPage.name} logo`} className="LogoForCoins"/>
                                                    <div className="CoinNameAndSymbol">
                                                        <a className="BlackColor NonUnderlinedHref" style={{fontSize: '16px'}}>{coinOnPage.name}</a>
                                                        <a href={`/coin/${coinOnPage.id}`} className="SymbolStyle NonUnderlinedHref" style={{fontSize: '10px'}}>{coinOnPage.symbol.toUpperCase()}</a>
                                                    </div>

                                                </td>
                                                <td className="AlignRight">${roundPrice(coinOnPage.current_price)}</td>
                                                <td className="AlignRight">${roundNumberWithPriceUnits(Math.round(coinOnPage.market_cap))}</td>
                                                <td className="AlignRight">{roundNumberWithPriceUnits(Math.round(coinOnPage.circulating_supply))} {coinOnPage.symbol.toUpperCase()}</td>
                                                <td className="AlignRight">{coinOnPage.max_supply ? `${roundNumberWithPriceUnits(Math.round(coinOnPage.max_supply))} ${coinOnPage.symbol.toUpperCase()}` : '∞'} </td>
                                                <td className={`AlignRight ${isInfitiniteSupply(coinOnPage) ? "GreenColor" : "RedColor"}`}>{percentageOfCirculatingSupply(coinOnPage)}</td>
                                         </>
                                    }
                                </tr>
                            })
                        }
                    </tbody>
                </table>


                {(numberOfPages >= 0)
                    ?
                    <div className="PagesSwitchMainContainer">
                        <div className="PagesSwitchSmallContainer">

                        <div className={`InnerPageSwitch ArrowPageSwitch ${currentPageNumber === 1 ? "GreyColor" : ""}`} onClick={() => lastPage()}>←</div>
                        <div className="InnerPageSwitch">{currentPageNumber}</div>
                        <div className={`InnerPageSwitch ArrowPageSwitch ${currentPageNumber === numberOfPages ? "GreyColor" : ""}`} onClick={() => nextPage()}>→</div>

                        </div>
                    </div>
                    : <div></div>
                }

            </section>
        </>
    )
}
