import './coin-detail.component.css'
import {useEffect, useState} from "react";
import {CoinService} from "../../services/services/coin/coin.service";
import {Alert, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {CoinDetailModel} from "../../services/models/coin-detail.model";
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'
import {LineController} from "chart.js";
import {roundNumberWithPriceUnits, roundPrice} from "../../services/utils/utils";
import {InflationChartComponent} from "../inflation-chart/inflation-chart.component";

ChartJS.register(LineController);

export interface CoinDetailProps {
    coinId: string;
}


export const CoinDetailComponent = ({ coinId }: CoinDetailProps) => {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [coinInfo, setCoinInfo] = useState<CoinDetailModel>();
    const [isPhoneFormat, setIsPhoneFormat] = useState(true);
    const [data, setData] = useState<{ x: number, y: number }[]>([])


    const navigator = useNavigate();

    // Manage the snackbar when the coin is not find
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        navigator('/')
    };

    const updateDimensions = () => {
        const width = window.innerWidth
        if (width > 1023) {
            setIsPhoneFormat(false)
        } else {
            setIsPhoneFormat(true)
        }
    }



    useEffect(() => {
        CoinService.getCoinsInfos(coinId).then(coinInfos => {
            setCoinInfo(coinInfos);

            const datas: { x: number, y: number }[] = [];
            for (let i = 0; i < coinInfos.historical.timestamp.length; i++) {
                datas.push({x: coinInfos.historical.timestamp[i], y: coinInfos.historical.circulating_supply[i]})
            }

            setData(datas);

        }).catch(e => {
            setOpen(true)
            setMessage(e.response.data.message)
        })

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () =>
            window.removeEventListener("resize",updateDimensions);
    }, [setCoinInfo, setOpen, setMessage, setData])




    return (<>
        <section className="MainBodySection">


            <div>

                <div className="FirstLineWithMainInformations">

                    <div className="MainInformationsAboutCoinMainSection">
                        <img src={coinInfo?.image} className="MainInformationsImageLogo" style={{height: isPhoneFormat ? '1.5em' : '3em'}}/>
                        <div className="MainInformationsCoinNameAndSymbol" style={{flexDirection: isPhoneFormat ? 'column' : 'row'}}>
                            <div className="MainInformationsCoinName" style={{fontSize: isPhoneFormat ? '18px' : '24px'}}>{coinInfo?.name}</div>
                            <div className="MainInformationsSymbol" style={{fontSize: isPhoneFormat ? '10px' : '13px'}}>{coinInfo?.symbol.toUpperCase()}</div>
                        </div>
                    </div>

                    <div className="MainInformationsPriceAndMarketCap">
                        <div className="MainInformationsPrice" style={{fontSize: isPhoneFormat ? '16px' : '20px'}}>{roundPrice(Number(coinInfo?.current_price))}$</div>
                        <div className="MainInformationsMarketCap" style={{fontSize: isPhoneFormat ? '11px' : '14px'}}>{coinInfo?.market_cap.toFixed(0)} $</div>
                    </div>

                </div>

            </div>

            <div style={{height: "20px"}}></div>

            <InflationChartComponent timestamps={coinInfo?.historical.timestamp || []} circulatingSupply={coinInfo?.historical.circulating_supply || []} maxSupply={coinInfo?.max_supply || null} isPhoneFormat={isPhoneFormat} />

        </section>


        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} message={message} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    </>)
}

// <div>{coinInfo?.circulating_supply.toFixed(0)} {coinInfo?.symbol.toUpperCase()} / {coinInfo?.max_supply} {coinInfo?.symbol.toUpperCase()}</div>
