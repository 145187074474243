import {HeaderLayout} from "../../layouts/header/header.layout";
import {FooterLayout} from "../../layouts/footer/footer.layout";
import {AboutUsInfosComponent} from "../../components/about-us-infos/about-us-infos.component";
import './about-us.page.css'
import ReactGA from "react-ga4";

export const AboutUsPage = () => {


    ReactGA.send({ hitType: "pageview", page: "/about-us" });

    return (
        <>
            <HeaderLayout />
            <div className="MainBodyStyle">
                <AboutUsInfosComponent />
            </div>

            <FooterLayout />
        </>
    )
}
