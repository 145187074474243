import ReactGA from "react-ga4";
import {HeaderLayout} from "../../layouts/header/header.layout";
import {FooterLayout} from "../../layouts/footer/footer.layout";
import {useParams} from "react-router-dom";
import {CoinDetailComponent} from "../../components/coin-detail/coin-detail.component";
import {env} from "../../env";

export const CoinDetailPage = () => {
    const params = useParams();

    ReactGA.send({ hitType: "pageview", page: `/coin-detail/${params.coinId}`});

    return (<>
        <HeaderLayout />

        <div className="MainBodyStyle">
            <CoinDetailComponent coinId={`${params.coinId}`}></CoinDetailComponent>
        </div>

        <FooterLayout />
    </>)

}
