import {BehaviorSubject} from "rxjs";
import axios from "axios";
import {CoinListModel, NumberOfPagesModel} from "../../models/coin-list.model";
import {env} from "../../../env";
import {CoinDetailModel} from "../../models/coin-detail.model";
import {CoinSearchResultModel} from "../../models/coin-search-result.model";

const URL_BACKEND: string = env.apiUrl;
// const URL_BACKEND: string = "https://api.coininflate.com/coin-list";


const numberOfPages$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
const coinsOnPage$: BehaviorSubject<CoinListModel[]> = new BehaviorSubject<CoinListModel[]>([]);

const currentUserPage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

const updateNumberOfPages = () => {
    return new Promise<number>((resolve, reject) => {
        axios.get<NumberOfPagesModel>(`${URL_BACKEND}/coin-list/pages/number`).then(numberOfPages => {
            numberOfPages$.next(numberOfPages.data.number_of_pages);
            resolve(numberOfPages.data.number_of_pages)
        }).catch(error => {
            reject(error)
        })
    });
}

const updateCoinsOnPage = (pageNumber: number) => {
    return new Promise<CoinListModel[]>((resolve, reject) => {
        axios.get<CoinListModel[]>(`${URL_BACKEND}/coin-list/page/${pageNumber}`).then(numberOfPages => {
            coinsOnPage$.next(numberOfPages.data);
            resolve(numberOfPages.data)
        }).catch(error => {
            reject(error)
        })
    });
}

const getCoinsInfos = (coinId: string): Promise<CoinDetailModel> => {
    return new Promise<CoinDetailModel>((resolve, reject) => {
        axios.get<CoinDetailModel>(`${URL_BACKEND}/coin/${coinId}`).then(coin => {
            /*

            const currentData: CoinDetailModel = coin.data;
            const currentCirculatingSupply: number[] = currentData.historical.circulating_supply

            const finalData: number[] = []



            for (let i = 1; i < currentCirculatingSupply.length - 1; i++) {

                if (currentCirculatingSupply[i] === 0) {
                    currentData.historical.circulating_supply[i] = (currentCirculatingSupply[i + 1] || currentCirculatingSupply[i - 1])
                }

                // currentData.historical.circulating_supply[i] = currentCirculatingSupply[i] + (currentCirculatingSupply[i - 1] / 2 ) + (currentCirculatingSupply[i + 1] / 2 )
            } */

            resolve(coin.data);
        }).catch(error => {
            reject(error);
        })
    })
}

const researchCoin = (searchValue: string): Promise<CoinSearchResultModel[]> => {
    return new Promise<CoinSearchResultModel[]>(async (resolve, reject) => {
        await axios.get(`${URL_BACKEND}/search/${searchValue}`).then(coinSearchList => {
            const currentData: CoinSearchResultModel[] = coinSearchList.data;
            resolve(currentData);
        }).catch(error => {
            reject(error);
        })
    })
}

const initialResearchCoin = (): Promise<CoinSearchResultModel[]> => {
    return new Promise<CoinSearchResultModel[]>(async (resolve, reject) => {
        await axios.get(`${URL_BACKEND}/search/initial-search`).then(coinSearchList => {
            const currentData: CoinSearchResultModel[] = coinSearchList.data;
            resolve(currentData);
        }).catch(error => {
            reject(error);
        })
    })
}


export const CoinService = {
    numberOfPages$,
    coinsOnPage$,
    currentUserPage$,
    updateNumberOfPages,
    updateCoinsOnPage,
    getCoinsInfos,
    researchCoin,
    initialResearchCoin
}
