

export const roundPrice = (price: number) => {

    if (price > 1) {
        return price.toFixed(2)
    }

    return price.toFixed(6)
}


export const roundNumberWithPriceUnits = (value: number): string => {
    if (value >= 1000 && value < 1000000) {
        return `${Number(value / 1000).toFixed(2)}k`
    }

    if (value >= 1000000 && value < 1000000000) {
        return `${Number(value / 1000000).toFixed(2)}M`
    }

    if (value >= 1000000000) {
        return `${Number(value / 1000000000).toFixed(2)}B`
    }

    return String(value);
}
