import './search-field-header.component.css';
import {useCallback, useEffect, useState} from "react";
import {CoinService} from "../../services/services/coin/coin.service";
import {
    Autocomplete, Avatar,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle, Divider,
    List,
    ListItem, ListItemAvatar, ListItemButton, ListItemText,
    TextField, Typography
} from "@mui/material";
import {CoinSearchResultModel} from "../../services/models/coin-search-result.model";
import {useNavigate} from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import ReactGA from "react-ga4";

export interface SearchFieldHeaderProps {
    open: boolean;
    onClose: () => void;
}

export const SearchFieldHeaderComponent = ({ open, onClose }: SearchFieldHeaderProps) => {

    const [searchInput, setSearchInput] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [coinList, setCoinList] = useState<CoinSearchResultModel[]>([]);

    const navigator: NavigateFunction = useNavigate();

    useEffect(() => {
        CoinService.initialResearchCoin().then(r => {
            setCoinList(r);
            setIsLoading(false);
        }).catch(e => {
            console.error(e);
            setIsLoading(false);
        })
    }, [])


    const handleChange = useCallback((e: any) => {
        e.preventDefault();

        const result: string = e.target.value;
        setSearchInput(result);

        setIsLoading(true);

        CoinService.researchCoin(e.target.value).then(r => {
            setCoinList(r);
            setIsLoading(false);
        }).catch(e => {
            console.error(e)
            setIsLoading(false);
        })

    }, [setIsLoading, setCoinList]);

    const navigateToPage = (coin: CoinSearchResultModel) => {
        navigator(`/coin/${coin.id}`);
        ReactGA.event(`${coin.id}`, {category: "search", action: "validate_search"})
        navigator(0);
    }

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            <Dialog onClose={handleClose} open={open} className="SearchFieldHeaderComponentDialog">
                <DialogTitle>Search...</DialogTitle>
                <DialogContent className="SearchFieldHeaderComponentDialogContent">
                    <div style={{height: '8px'}}></div>
                    <TextField id="outlined-basic" label="Search" variant="outlined" onChange={handleChange} value={searchInput} style={{width: "100%"}} />

                    <div className="SearchFieldHeaderComponentDialogContentResult">

                        {isLoading
                            ?
                            <><CircularProgress /></>
                            :
                            <>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader">
                                    {coinList.map((coinResult, index) => {
                                        return (
                                            <ListItemButton onClick={() => navigateToPage(coinResult)} key={`${index}-${coinResult.id}`}>
                                                <ListItemAvatar>
                                                    <Avatar src={coinResult.image} />
                                                </ListItemAvatar>
                                                <ListItemText primary={coinResult.name}/>
                                            </ListItemButton>
                                        )
                                    })}
                                </List>

                            </>
                        }
                    </div>
                </DialogContent>

            </Dialog>
        </>
    )
}
