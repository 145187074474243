import React from 'react';
import './App.css';
import { AppRoutes } from "./routes";
import ReactGA from "react-ga4";

ReactGA.initialize("G-VYEXZ7N4EH");
function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
