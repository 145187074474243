
import './footer.layout.css'
import {List, ListItemButton, Typography} from "@mui/material";

export const FooterLayout = () => {


    return (
        <footer className="Footer">

            <section className="FooterUtilSection">

                <div className="FooterLinks">

                    <Typography variant="h6">Links</Typography>


                    <List component="nav">
                        <ListItemButton>Join Us On Twitter - <a href="https://twitter.com/coin_inflate" className="LinkEmailFooter">@coin_inflate </a></ListItemButton>
                        <ListItemButton>Contact us at : <a href = "mailto: infos@coininflate.com" className="LinkEmailFooter"> infos@coininflate.com</a></ListItemButton>
                    </List>

                </div>

                {/*
                <div className="FooterDetails">
                    <Typography variant="h6">Details</Typography>

                </div>
                <div className="FooterLegal">
                    <Typography variant="h6">Legal</Typography>

                </div>*/}
            </section>

            <div className="FooterCredentials">CoinInflate™ | 2022 | All rights reserved</div>
            <div className="FooterCredentials">This site was created with ❤️ by the <a href="https://neowhiz.agency/">NeoWhiz</a> agency</div>

        </footer>
    )
}
