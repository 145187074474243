import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {CoinListPage} from "../pages/coin-list/coin-list.page";
import {AboutUsPage} from "../pages/about-us/about-us.page";
import {CoinDetailPage} from "../pages/coin-detail/coin-detail.page";
import {SearchFieldHeaderComponent} from "../components/search-field-header/search-field-header.component";


export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<CoinListPage />} path="/"></Route>
                <Route element={<AboutUsPage />} path="/about-us"></Route>
                <Route element={<CoinDetailPage />} path="/coin/:coinId"></Route>
                {/*<Route element={<SearchFieldHeaderComponent  isMobile={false}/>} path="/debug"></Route>*/}
            </Routes>
        </BrowserRouter>
    )
}
