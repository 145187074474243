import {HeaderLayout} from "../../layouts/header/header.layout";
import {FooterLayout} from "../../layouts/footer/footer.layout";
import {CoinListingComponent} from "../../components/coin-listing/coin-listing.component";
import './coin-list.page.css'
import ReactGA from "react-ga4";


export const CoinListPage = () => {

    ReactGA.send({ hitType: "pageview", page: "/" });

    return (
        <>
            <HeaderLayout />
            <div className="MainBodyStyle">
                <CoinListingComponent />
            </div>

            <FooterLayout />
        </>
    )
}
