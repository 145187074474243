import './header.layout.css'

import smallLogo from './../../assets/images/small-logo.svg';
import largeLogo from './../../assets/images/large-logo.svg';
import {Icon, IconButton, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import {SearchFieldHeaderComponent} from "../../components/search-field-header/search-field-header.component";


export const HeaderLayout = () => {

    const [width, setWindowWidth] = useState(0);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [logoUrl, setLogoUrl] = useState(smallLogo)

    const navigator = useNavigate();


    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize",updateDimensions);
        }

    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth
        if (width > 1023) {
            setLogoUrl(largeLogo);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setLogoUrl(smallLogo)
        }
        setWindowWidth(width)
    }

    const navigateTo = (destination: string) => {
        navigator(`/${destination}`)
    }

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <div className="DarkRectangle"></div>

            <header className="Header">

                <img className="HeaderLogo HeaderLink" src={logoUrl} alt="CoinInflate Logo" onClick={() => navigateTo('')}></img>

                <div className="HeaderLinks">
                    <a className="HeaderLink HeaderMargin" onClick={() => navigateTo('')}>
                        <Typography sx={{fontWeight: 'bold'}}>Home</Typography>
                    </a>

                    {isMobile ? <></> :
                        <>
                            <a className="HeaderLink HeaderMargin" onClick={() => navigateTo('about-us')}>
                                <Typography sx={{fontWeight: 'bold'}}>About Us</Typography>
                            </a>
                        </>
                    }


                    <IconButton color="inherit" aria-label="upload picture" component="label" onClick={() => handleClickOpen()} className="SearchField">
                        <SearchIcon />
                    </IconButton>
                </div>

                <SearchFieldHeaderComponent open={open} onClose={handleClose} />

            </header>
        </>
    )
}
