import "./inflation-chart.component.css"
import {useEffect, useRef} from "react";

import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'
import {ChartData, ChartDataset, ChartOptions, ChartType, CoreChartOptions} from "chart.js";

import Hammer from "hammerjs";
import zoomPlugin from "chartjs-plugin-zoom";
import {roundNumberWithPriceUnits} from "../../services/utils/utils";

ChartJS.register(zoomPlugin);

export interface InflationChartComponentProps {
    timestamps: number[];
    circulatingSupply: number[];
    maxSupply: number | null;
    isPhoneFormat: boolean;
}

export const InflationChartComponent = ({timestamps, circulatingSupply, maxSupply, isPhoneFormat}: InflationChartComponentProps) => {


    const datasets: ChartDataset[] = [
        {
            label: "Circulating supply",
            data: circulatingSupply,
            backgroundColor: "#36A2EB"
        },
        {
            label: maxSupply ? "Maximum supply" : "Unlimited supply",
            type: "line",
            borderColor: "#FF6384",
            data: [
                {
                    x: timestamps[0],
                    y: maxSupply || NaN
                },
                {
                    x: timestamps.at(-1) || NaN,
                    y: maxSupply || NaN
                }
            ],
            pointStyle: 'rect',
        },
    ]

    const data: ChartData = {
        // labels: timestamps.map(timestamp => (new Date(timestamp)).toLocaleDateString()),
        labels: timestamps,
        datasets: datasets,
    };

    const options: ChartOptions = {
        responsive: true,
        aspectRatio: isPhoneFormat ? 0.8 : 2.5,
        layout: {
         padding: {
             top: 10,
         }
        },

        scales:{
            y: {
                ticks: {
                    display: true,
                    align: 'end',
                    minRotation: 90,
                    maxRotation: 90,
                    callback: (value, index, ticks) =>  roundNumberWithPriceUnits(Number(value)),
                }
            },
            x: {
                ticks: {
                    minRotation: 90,
                    maxRotation: 90,
                    callback: (timestamp, index, ticks) => {
                        return (new Date(timestamps[+timestamp])).toLocaleDateString()
                    }
                }
            }
        },
        plugins: {
            zoom: {
                limits: {
                    x: { min: "original", max: "original" },
                    y: { min: "original", max: "original" },
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    drag: {
                        enabled: true,
                        modifierKey: "shift",
                    },
                    mode: "xy",
                },
                pan: {
                    enabled: true,
                    mode: "xy",
                },
            },
            title: {
                display: true,
                text: "Coins / tokens in circulation",
            },
        },

    };



    const type: any = 'bar';


    return (
        <Chart
         data={data}
         type={type}
         options={options}
        />
    );

}
