import './about-us-infos.component.css'

export const AboutUsInfosComponent = () => {



    return (
        <>
            <section className="MainBodySection">

                <h2>Why CoinInflate ?</h2>
                <p>
                    CoinInflate was thought up after the many bankruptcies that have occurred in the crypto-currency world. We realised that in many cases we could detect that a crypto-currency was potentially going to lose value when the amount of that crypto-currency in circulation increased very quickly. These crypto-currencies are often referred to as 'high inflation crypto-currencies'.
                </p>
                <p>
                    Our goal, through CoinInflate, is to measure this inflation on a daily basis, so that you can see if a large amount of tokens has been printed, and therefore, allow you to better manage your investments.
                </p>

                <h2>Evolution perspective</h2>
                <p>
                Our adventure starts in November 2022, so CoinInflate is only at its beginning, we aim to add many features that will aim to guide all types of crypto investors.
                </p><p>
                In the future, we want to become a platform that will list many indicators that will be both fundamental and technical.
                </p><p>
                Of course, we are open to any suggestions, improvements, or even a quick hello from you. Do not hesitate to contact us at infos@coininflate.com
                </p>

                <h2>Who is the founder</h2>
                <p>
                    We won't go on and on about it, you can check my twitter profile here <a href="https://twitter.com/DandreaWilliam">@DandreaWilliam </a>:)
                </p>


            </section>

        </>
    )
}
